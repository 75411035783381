<template>
  <div class="settings-subscriptions">
    <div>
      <am2-card-skeleton
        v-if="displayPlanCardSkeletons"
        class="base-plan-card"
        :show-as="$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list'"
        :style="{
          height: '122px',
        }"
      />
      <div
        v-if="planAndAccountEntitlements || advancedPaymentPlanSubscriptionInformation.basePaymentSubscription"
        v-for="basePlan of advancedPaymentPlanSubscriptionInformation.basePaymentPlans"
        :key="basePlan.puid"
        class="base-plan-card"
      >
        <!-- :payment-subscription prop is the PC 1.0 subscription plan -->
        <!-- :payment-subscription-entitlement prop is the PC 2.0 subscription and related entitlements -->
        <!-- either :payment-subscription or :payment-subscription-entitlements prop should be valid at one time -->
        <am2-base-plan-card
          v-if="planAndAccountEntitlements.filter(x => x.planOid === basePlan.oid)[0] || basePlan.paymentSubscriptions[0]"
          :show-as="$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list'"
          :payment-plan="basePlan"
          :payment-subscription="basePlan.paymentSubscriptions[0]"
          :payment-subscription-entitlements="planAndAccountEntitlements.filter(x => x.planOid === basePlan.oid)[0]"
          :add-on-subscriptions="advancedPaymentPlanSubscriptionInformation.addOnPaymentSubscriptions"
          :primary-payment-source="primaryPaymentSource"
          @subscriptionStateUpdate="handleSubscriptionStateUpdate"
        />
      </div>
      <!-- Temporarily preventing the display of a base plan card -->
      <div
        v-if="false"
        class="base-plan-card"
      >
        <am2-base-plan-card
          :base-plan="null"
          :show-as="$arMediaQuery.pageContent.maxWidth('sm') ? 'tiles' : 'list'"
        />
      </div>
      <!-- Waiting for confirmation of add-ons -->
      <div
        v-if="displayPlanCardSkeletons"
        class="second-title-section">
        <ar-text
          size="md"
          text="Additional options"
        />
      </div>
      <div
        v-if="displayPlanCardSkeletons"
        class="add-on-cards-section"
      >
        <am2-card-skeleton
          v-for="n in 5"
          :key="n"
          :class="[
            'add-on-card',
            $arMediaQuery.pageContent.maxWidth('sm') && 'full-width',
          ]"
          show-as="tiles"
          :level="$arMediaQuery.pageContent.maxWidth('xs') ? 2 * n : 2 * Math.ceil(n / 2)"
          :style="{
            height: '309px',
          }"
        />
      </div>
      <div v-if="enabledPaymentPlans" class="add-on-cards-section">
        <am2-add-on-plan-card
          v-for="(addOnPlan, idx) of enabledPaymentPlans"
          :key="idx"
          :payment-plan="addOnPlan"
          :primary-payment-source="primaryPaymentSource"
          :base-subscription="advancedPaymentPlanSubscriptionInformation.basePaymentSubscription"
          :size="$arMediaQuery.pageContent.maxWidth('sm') ? 'small' : 'regular'"
          :class="[
            'add-on-card',
            $arMediaQuery.pageContent.maxWidth('sm') && 'full-width',
          ]"
          @subscriptionStateUpdate="handleSubscriptionStateUpdate"
        />
      </div>
      <div v-if="audienceManagerEntitlements.length > 0">
        <div class="audience-manager-entitlement-limit-progress-bar-wrapper">
          <div
            v-if="fanProfileEntitlements.length > 0"
            class="audience-manager-fan-profile-entitlement-limit-progress-bar-wrapper"
            :style="entitlementWrapperStyle(fanProfileEntitlements)"
            style="margin-top: 25px;"  
          >
            <div
              v-for="entitlement of fanProfileEntitlements"
              :key="entitlement.entitlementOid"
              class="audience-manager-fan-profile-entitlement-limit-progress-bar"
            >
              <am2-entitlement-limit
                :title="entitlement.featureName"
                :total-progress="entitlement.planEntitlementLimit"
                :current-progress="entitlement.planEntitlementLimitConsumed || 0"
              />
            </div>
          </div>
          <div
            v-if="companyEntitlements.length > 0"
            class="audience-manager-company-entitlement-limit-progress-bar-wrapper"
            :style="entitlementWrapperStyle(companyEntitlements)"
            style="margin-top: 50px;"
          >
            <div
              v-for="entitlement of companyEntitlements"
              :key="entitlement.entitlementOid"
              class="audience-manager-company-entitlement-limit-progress-bar"
            >
              <am2-entitlement-limit
                :title="entitlement.featureName"
                :total-progress="entitlement.planEntitlementLimit"
                :current-progress="entitlement.planEntitlementLimitConsumed || 0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  layout: 'default',
  data() {
    return {
    };
  },

  computed: {
    ...mapState({
      paymentPlanList: state => state.payment.paymentPlanList,
      isFetchingPaymentPlans: state => state.payment.isFetchingPaymentPlans,
      paymentSubscriptionList: state => state.payment.paymentSubscriptionList,
      isFetchingPaymentSubscriptions: state => state.payment.isFetchingPaymentSubscriptions,
      paymentSubscriptionEntitlementsList: state => state.payment.paymentSubscriptionEntitlementsList,
      isFetchingProviderPaymentSubscriptionEntitlements: state => state.payment.isFetchingProviderPaymentSubscriptionEntitlements
    }),
    ...mapGetters({
      primaryPaymentSource: 'payment/primaryPaymentSource',
      advancedPaymentPlanSubscriptionInformation: 'payment/advancedPaymentPlanSubscriptionInformation',
    }),
    planAndAccountEntitlements() {
      if (!!this.isFetchingProviderPaymentSubscriptionEntitlements) {
        return [];
      } else {
        return this.paymentSubscriptionEntitlementsList.map(subscription => {
          const filteredEntitlements = subscription.planEntitlements.filter(entitlement => {
            const featureNameLower = entitlement.featureName.toLowerCase();
            return featureNameLower === 'contacts' || featureNameLower === 'email sends' || featureNameLower === 'email-sends';
          });

          return {
            ...subscription,
            planEntitlements: filteredEntitlements
          };
        });
      }
    },
    audienceManagerEntitlements() {
      if (!!this.isFetchingProviderPaymentSubscriptionEntitlements) {
        return [];
      } else {
        return this.paymentSubscriptionEntitlementsList.flatMap(subscription => {
          const filteredEntitlements = subscription.planEntitlements.filter(entitlement => {
            const featureNameLower = entitlement.featureName.toLowerCase();
            return featureNameLower !== 'contacts' && featureNameLower !== 'email sends' && featureNameLower !== 'email-sends';
          });

          return filteredEntitlements;
        });
      }
    },
    fanProfileEntitlements() {
      if (!!this.isFetchingProviderPaymentSubscriptionEntitlements) {
        return [];
      } else {
        return this.audienceManagerEntitlements.filter(entitlement => {
            const featureNameLower = entitlement.featureName.toLowerCase();
            return featureNameLower === 'tags' || featureNameLower === 'segments' || featureNameLower === 'custom fields' || featureNameLower === 'custom-fields';
        });
      }
    },
    companyEntitlements() {
      if (!!this.isFetchingProviderPaymentSubscriptionEntitlements) {
        return [];
      } else {
        return this.audienceManagerEntitlements.filter(entitlement => {
            const featureNameLower = entitlement.featureName.toLowerCase();
            return featureNameLower === 'email senders' || featureNameLower === 'email-senders' || featureNameLower === 'user seats' || featureNameLower === 'user-seats';
        });
      }
    },
    displayPlanCardSkeletons() {
      return this.isFetchingPaymentPlans || this.isFetchingPaymentSubscriptions || !!this.isFetchingProviderPaymentSubscriptionEntitlements;
    },
    validBasePaymentPlans() {
      if (!!this.advancedPaymentPlanSubscriptionInformation.basePaymentSubscription) {
        return this.advancedPaymentPlanSubscriptionInformation.basePaymentPlans.filter((basePaymentPlan) => {
          if (basePaymentPlan.paymentSubscriptions.length === 0) {
            return false;
          }
          return basePaymentPlan;
        });
      }
      else {
        return [];
      }
    },
    enabledPaymentPlans() {
      return this.advancedPaymentPlanSubscriptionInformation.addOnPaymentPlans.filter((paymentPlan) => {
        if (!paymentPlan.featureIds || paymentPlan.featureIds.length === 0) {
          return false;
        }

        return !paymentPlan.featureIds.includes('facebook-messenger')
      })
    }
  },

  async created() {
    this.initializeDatas();

  },

  methods: {
    ...mapActions([
      'payment/FETCH_PAYMENT_SUBSCRIPTIONS',
      'payment/FETCH_PAYMENT_PLANS',
      'payment/FETCH_PAYMENT_CUSTOMER',
      'payment/FETCH_PROVIDER_PAYMENT_SUBSCRIPTION_ENTITLEMENTS'
    ]),
    ...mapMutations([
      'payment/SET_PAYMENT_CUSTOMER',
    ]),
    async initializeDatas() {
      try {
        await this['payment/FETCH_PROVIDER_PAYMENT_SUBSCRIPTION_ENTITLEMENTS']();
        await Promise.all([
          this['payment/FETCH_PAYMENT_CUSTOMER'](),
          this['payment/FETCH_PAYMENT_PLANS']({}),
          this['payment/FETCH_PAYMENT_SUBSCRIPTIONS']({})
        ]);
      } catch (e) {
        console.error(e);
      }
    },
    handleSubscriptionStateUpdate() {
      this['payment/FETCH_PAYMENT_SUBSCRIPTIONS']();
    },
    entitlementWrapperStyle(entitlements) {
      if (entitlements.length > 0) {
        return {
          border: "1px solid #DCDEE4",
          borderRadius: "4px",
          backgroundColor: "white",
          padding: "30px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between"
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>

.audience-manager-fan-profile-entitlement-limit-progress-bar {
  width: 30%;
}

.audience-manager-company-entitlement-limit-progress-bar {
  width: 47%;
}

.settings-subscriptions {
  max-width: 800px;
  margin: 59px auto 0;

  .loading-section {
    height: 122px;
  }

  .first-title-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .second-title-section {
    margin-top: 54px;
  }

  .base-plan-card {
    margin-top: 26px;
  }

  .add-on-cards-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 26px;

    .add-on-card {
      flex-basis: calc(50% - 20px);
      flex-shrink: 0;
      margin-bottom: 40px;

      &.full-width {
        flex-basis: unset;
        width: 100%;
      }
    }
  }
}
</style>
