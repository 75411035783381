<template>
  <am2-card-container
    class="plan-card"
    :style="{
      border: 'none',
    }"
  >
    <div
      v-if="isMostPopular"
      class="header-section"
    >
      <ar-text
        size="xs"
        text="MOST POPULAR"
        align="center"
        weight="bold"
        :style="{
          color: 'white',
        }"
      />
    </div>
    <div class="content-section">
      <div class="info-section">
        <ar-text
          size="md"
          :text="name"
          align="center"
          weight="bold"
        />
        <ar-text
          size="lg"
          :text="priceCopy"
          :style="{
            color: $arStyle.color.purple500,
          }"
          class="u-margin-top-10"
        />
        <ar-text
          size="xs"
          :text="periodAndUnit"
          :style="{
            color: $arStyle.color.skyBlueGrey700,
          }"
          class="u-margin-top-3"
        />
        <ar-text
          size="xs"
          :text="numberOfContactsCopy"
          :style="{
            color: $arStyle.color.skyBlueGrey700,
          }"
          class="u-margin-top-2"
        />
      </div>
      <ar-simple-button
        v-if="hasCustomBaseSubscription"
        text="Contact Sales"
        type="gradient-purple"
        class="confirm-button"
        @click="handleContactSalesClick"
      />
      <ar-simple-button
        v-else
        :text="buttonText"
        :type="buttonType"
        outlined
        class="confirm-button"
        @click="handleButtonClick"
      />
      <div
        class="feature-list u-margin-top-12"
      >
        <div
          v-for="(feature, featureIdx) of features"
          :key="feature.key"
        >
          <div
            class="feature-section"
          >
            <ar-icon
              v-if="paymentPlan.featureIds.indexOf(feature.key) > -1"
              name="notification-success"
              :color="$arStyle.color.purple500"
              width="20px"
            />
            <div
              v-else
              class="empty-circle"
            >
            </div>
            <ar-text
              size="xs"
              :text="feature.name"
              :style="{
                marginLeft: '10px',
              }"
              v-tooltip.top="{
                content: feature.description,
              }"
            />
          </div>
          <ar-divider
            v-if="featureIdx !== features.length - 1"
            :style="{
              background: $arStyle.color.skyBlueGrey400,
              margin: '14px 0 15px',
            }"
          />
        </div>
      </div>
    </div>
  </am2-card-container>
</template>

<script>
import accounting from 'accounting';
import { getMinimumRequiredPlanTierByNumberOfContacts, getBasePaymentPlanTier } from '@/utils/payment/';

export default {
  name: 'PlanCard',
  props: {
    paymentPlan: {
      type: Object,
      default: null,
    },
    paymentSubscription: {
      type: Object,
      default: null,
    },
    basePaymentSubscription: {
      type: Object,
      default: null,
    },
    realContactsNumber: {
      type: Number,
      default: null,
    },
    isMostPopular: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      features: [
        {
          key: 'filters',
          name: 'Filters',
          description: 'Filter your audience based on spend, events attended, age, gender, location & more!',
        },
        {
          key: 'events',
          name: 'Events',
          description: 'Import all your events from the different ticketing platforms you use. Track your ticket sales, discover trends & unlock customer insights.',
        },
        {
          key: 'save-filters-and-tags',
          name: 'Segments & tags',
          description: 'Using segments & tags, you can create segments to help you target your marketing.',
        },
        {
          key: 'insights',
          name: 'Insights',
          description: 'Identify your top spenders, most loyal attendees, demographics, most popular artists/tracks & potential ambassadors.',
        },
        {
          key: 'customer-profiles',
          name: 'Customer Profiles',
          description: 'Everything you need to know about your fans — including much they’ve spent on tickets to your events, which of your events they’ve attended & their contact info.',
        },
        {
          key: 'sms',
          name: 'SMS Messaging *',
          description: 'Text message your audience, across any country. <br /><br />* SMS Credits not included.',
        },
        {
          key: 'facebook-custom-audiences',
          name: 'Facebook Custom Audiences',
          description: 'Custom Audiences Automatically sync your segments to Facebook Custom Audiences.',
        },
        {
          key: 'email',
          name: 'Email Marketing',
          description: 'Beautiful email marketing specifically designed for event organizers.',
        },
      ],
    };
  },
  computed: {
    currentlySubscribed() {
      return !!this.paymentSubscription;
    },
    hasCustomBaseSubscription() {
      if (!this.basePaymentSubscription) {
        return false;
      }
      return !!this.basePaymentSubscription?.paymentPlan?.additionalInfo?.metaData?.childOf;
    },
    isSubscriptionNotRenewing() {
      return this.paymentSubscription && this.paymentSubscription.providerStatus === 'non_renewing';
    },
    name() {
      if (!this.paymentPlan) return null;
      return this.paymentPlan.additionalInfo.invoiceName || this.paymentPlan.name;
    },
    minimumRequiredPlanTier() {
      if (this.basePaymentSubscription) {
        // Case 1: If you already got a base subscription, we need to display price based on that subscription's planQuantity
        return getBasePaymentPlanTier({
          paymentPlan: this.paymentPlan,
          planQuantity: this.basePaymentSubscription.additionalInfo.planQuantity,
        });
      } else {
        // Case 2: If you don't have any base subscription, let's get tier based on how many contacts you have
        return getMinimumRequiredPlanTierByNumberOfContacts({
          paymentPlan: this.paymentPlan,
          numberOfContacts: this.realContactsNumber,
        });
      }
    },
    numberOfContactsCopy() {
      if (!this.minimumRequiredPlanTier) {
        return null;
      }
      const pricingModelDescription = this.paymentPlan.additionalInfo?.metaData?.presentation?.pricingModelDescription;
      return pricingModelDescription ? pricingModelDescription.replace(/{{contactsLimit}}/g, accounting.format(this.minimumRequiredPlanTier.endingUnit)) : null;
    },
    priceCopy() {
      if (!!this.minimumRequiredPlanTier) {
        return `$${accounting.formatNumber(this.minimumRequiredPlanTier.price / 100)}`;
      }
      return null;
    },
    periodAndUnit() {
      return `${this.paymentPlan.billingPeriod === 'year' ? 'Per Year' : 'Per Month'} (${this.paymentPlan.currency})`;
    },
    buttonType() {
      if (this.currentlySubscribed) {
        return 'purple';
      }
      return 'gradient-purple';
    },
    buttonText() {
      if (this.isSubscriptionNotRenewing) {
        return 'Renew';
      } else if (this.paymentSubscription) {
        return 'Update Plan';
      } else {
        return `Select Plan`;
      }
    },
  },
  methods: {
    handleButtonClick() {
      this.$emit('click');
    },
    handleContactSalesClick() {
      window.open('https://audiencerepublic.com/contact-us?type=organizer');
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-card {
  box-shadow: 0 0 15px 0 rgba($blueGrey800, 0.1);
  overflow: hidden;

  .header-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $purple500;
    height: 30px;
  }

  .content-section {
    padding: 39px 30px;

    .info-section {
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    .confirm-button {
      width: 100%;
      height: 60px;
      margin-top: 40px;
    }

    .feature-list {
      .feature-section {
        display: flex;
        align-items: center;

        .empty-circle {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid $blueGrey500;
        }
      }
    }
  }
}
</style>
